<template>
  <div
    class="group relative flex flex-col p-4"
    :class="{
      'bg-white': white,
      'bg-gray-10': !white,
    }"
  >
    <div class="absolute left-4 top-4 z-10">
      <div
        v-if="isSale"
        class="flex size-7 items-center justify-center rounded-full bg-red-500"
      >
        <span class="text-xs font-medium text-white">%</span>
      </div>
    </div>
    <div class="absolute right-4 top-4 z-10 flex gap-1">
      <ClientOnly>
        <ShopProductWishlistHeart
          v-if="wishlist"
          :product="product"
          class="size-7 rounded-full p-1.5 transition-colors duration-500 hover:text-red-500"
          :class="{
            'bg-white': !white,
            'bg-gray-10': white,
          }"
        />
      </ClientOnly>
      <ShopProductAddToCart
        v-if="
          addToCart &&
          (product.extensions?.hiddenPrice?.isHiddenPrice !== true ||
            isProfessional)
        "
        :product="product"
        class="transition-colors duration-500 hover:bg-gray-500 hover:text-white"
        :class="{
          'bg-white': !white,
          'bg-gray-10': white,
        }"
      />
    </div>
    <div>
      <NuxtLink :to="getProductRoute(product)" class="overflow-hidden">
        <ShopSharedImage
          sizes="500px"
          :media="product.cover?.media"
          :alt="getTranslatedProperty(product, 'name')"
          class="aspect-[240/204] w-full object-contain mix-blend-multiply transition-transform duration-500 group-hover:scale-105"
        />
      </NuxtLink>
    </div>
    <div class="relative mt-auto flex flex-col lg:mt-4 lg:grow">
      <span
        v-if="category"
        class="text-2xs font-medium uppercase tracking-wider text-gray-300"
      >
        {{ getTranslatedProperty(category, 'name') }}
      </span>

      <NuxtLink :to="getProductRoute(product)">
        <span class="text-base font-bold text-gray-500">
          {{ getTranslatedProperty(product, 'name') }}
        </span>
      </NuxtLink>

      <div class="mt-2 flex items-center justify-between">
        <ShopProductPrice :product="product" :inverse-colors="!white" />
        <NuxtLink :to="getProductRoute(product)">
          <IconArrowRight
            class="h-auto w-4 transition-all group-hover:translate-x-1"
          />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getTranslatedProperty } from '@shopware-pwa/helpers-next'
import { toRefs } from 'vue'

import type { Schemas } from '#shopware'
import { useShopRouter } from '~/composables/useShopRouter'

const props = withDefaults(
  defineProps<{
    product: Schemas['Product']
    wishlist?: boolean
    addToCart?: boolean
    white?: boolean
  }>(),
  {
    wishlist: true,
    addToCart: true,
    white: false,
  }
)
const { getProductRoute } = useShopRouter()
const { product } = toRefs(props)
const { user } = useUser()
const { isProfessional } = useCustomer(user)

const isSale = computed(() => {
  return product.value.calculatedPrice?.listPrice !== null
})

const category = computed(() => product.value.categories?.[0])
</script>
